<template>
  <v-dialog :value="value" @input="close" max-width="600px">
    <v-card style="border-radius: 30px !important;">

      <v-row no-gutters align="center">

        <!-- Title + Close icon -->
        <v-col cols="12" class="mt-6">
          <v-row no-gutters>
            <v-col cols="8" class="offset-2">
              <h2 class="font-weight-bold text-center" style="margin:0 auto !important;">{{$t('account.profile.mainDialog.title')}}</h2>
            </v-col>
            <v-col cols="1" class="offset-1">
              <v-btn icon @click="close(false)">
                <v-icon color="primary">fal fa-times</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="10" offset-md="1" class="mt-6">
          <v-form @submit.prevent ref="form" lazy-validation>
            <v-row no-gutters>

              <!-- Firstname -->
              <v-col cols="12" md="6">
                <v-text-field
                  class="mr-md-1"
                  :label="$t('account.profile.mainDialog.form.firstname')"
                  v-model="form.person.firstname"
                  outlined
                  required
                  :maxlength="255"/>
              </v-col>

              <!-- Name -->
              <v-col cols="12" md="6">
                <v-text-field
                  class="ml-md-1"
                  :label="$t('account.profile.mainDialog.form.name')"
                  v-model="form.person.name"
                  outlined
                  required
                  :maxlength="255"/>
              </v-col>

              <!-- Email -->
              <v-col cols="12">
                <v-text-field
                  v-model="form.email"
                  :label="$t('account.profile.mainDialog.form.email')"
                  maxlength="255"
                  outlined>
                </v-text-field>
              </v-col>

              <v-col cols="12">
                <AlertBar />
              </v-col>
            </v-row>
          </v-form>
        </v-col>

        <!-- Button -->
        <v-col cols="12" class="my-6 text-center">
            <v-btn
              @click="customSubmit"
              rounded
              width="200"
              color="primary"
              class="font-weight-bold"
              :loading="isLoadingBtn.save"
              style="border-radius: 10px !important;font-size: 16px !important;"
            >
              {{$t('common.buttons.save')}}
            </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>

</template>

<script>
  // Mixins
  import dialogMixin from "@/mixins/dialog"

  export default {

    name: "ProfileChangeMainDialog",

    components: {

      ButtonBarFormDialog: () => import("@/components/Common/Dialogs/ButtonBarFormDialog"),
      AlertBar: () => import("@/components/Common/AlertBar")
    },

    mixins: [dialogMixin],

    data() {

      return {
        defaultForm: {

          person: {firstname: "", name: ""},
          preferences: {},
          email: ""
        },

        isLoadingBtn: {
          save: false
        }
      }
    },

    mounted() {

    },

    methods: {
      customSubmit() {

        this.isLoadingBtn.save = true;

        this.$http.put(`/map/users/${this.$session.get('id')}`, this.form,
        {
          headers:
          {
            Authorization: "Bearer " + this.$session.get('jwt')
          }
        })
        .then(res => {
          this.$store.commit("toast/showSuccess", this.$t('account.profile.mainDialog.success'));
          this.close(true);
        })
        .catch(err => {
          if (err &&
            err.response &&
            err.response.status === 400 &&
            this.$t('account.profile.mainDialog.errors').hasOwnProperty(err.response.data.type)) {
            this.$store.commit("alert/showError", this.$t(`account.profile.mainDialog.errors.${err.response.data.type}`));
          }
          else
            this.$store.commit("alert/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoadingBtn.save = false;
        })
      },

      onInputLang(val) {
        this.form.preferences.lang = val;
      },

      onInputCountry(val) {
        this.form.preferences.country = val;
      },

      onInputTimezone(val) {
        this.form.preferences.timezone = val ? val : null;
      }
    }
  }
</script>

<style scoped>

</style>